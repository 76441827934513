import React from 'react'

import { Layout, SEO, About, CaseStudy } from '../components'

import previewAndre from '../images/andre.png'
import previewBrandon from '../images/brandon.png'
import previewJaime from '../images/jaime.png'
import previewOsman from '../images/osman.png'
import previewSlava from '../images/slava.png'
import previewAbner from '../images/abner.png'
import previewJanselle from '../images/janselle.png'
import previewSam from '../images/sam.png'
import socialCard from '../images/social_card_credits.png'

const currentCrew = [
  {
    name: 'Brandon Stewart',
    role: 'Product Owner',
    picture: previewBrandon,
  },
]

const pastCrew = [
  {
    name: 'Andre Benatti',
    role: 'Frontend Developer',
    picture: previewAndre,
  },
  {
    name: 'Jaime Wright',
    role: 'UI/UX Research & Designer',
    picture: previewJaime,
  },
  {
    name: 'Janselle Justo',
    role: 'Tech Admin for Design & Mkt',
    picture: previewJanselle,
  },
  {
    name: 'Osman Pontes',
    role: 'Fullstack Developer  ',
    picture: previewOsman,
  },
  {
    name: 'Samuel Timbó',
    role: 'Fullstack Developer',
    picture: previewSam,
  },
  {
    name: 'Slava Mazhaliuk',
    role: 'Android Developer',
    picture: previewSlava,
  },
  {
    name: 'Vitor Abner',
    role: 'Fullstack Developer',
    picture: previewAbner,
  },
]

const description =
  'We are a technology company focused on growing mutual value with our clients. We strategize with our clients from idea to final product.'

const caseStudyDescription = (
  <div>
    <p>
      We are a technology company focused on growing mutual value with our
      clients while creating meaningful experiences and products. We chose the
      name Gnar, pronounced "NAR", because it is a mindset and an expression of
      character.
    </p>
    <br />
    <p>
      For the past decade, our team has been developing products that solve
      real problems. We work as a team by sharing goals, workflows, and
      communication between team members. Using our methodology, we strategize
      with our clients from idea to final product.
    </p>
  </div>
)

const Credits = ({ location }) => (
  <Layout location={location}>
    <SEO
      title="Credits"
      socialTitle="People behind GNAR technologies"
      image={socialCard}
      description={description}
    />
    <CaseStudy
      projectName="Who we are"
      description={caseStudyDescription}
      showcase
      isShort
    />
    <About title="Current creators" crew={currentCrew} />
    <About title="Past creators" crew={pastCrew} />
  </Layout>
)

export default Credits
